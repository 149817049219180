export const textConfirmDelete = "Bạn có chắc chắn muốn xóa?";
export const NAME_VIETCOMBANK = "Vietcombank";
export const NAME_TECHCOMBANK = "Techcombank";
export const NAME_TPBANK = "TPBank";
export const NAME_ACB = "ACB";
export const NAME_MBBANK = "Mbbank";
export const NAME_MOMO = "Momo";
export const NAME_VIETINBANK = "Vietinbank";
export const NAME_AGRIBANK = "Agribank";
export const NAME_BIDV = "BIDV";
export const NAME_SACOMBANK = "SACOMBANK";
export const NAME_VPBANK = "Vpbank";
export const NAME_MSB = "MSB";
export const NAME_DONGA = "DongAbank";
export const NAME_PVCOMBANK = "PvcomBank";
export const NAME_NAMABANK = "NamABank";
export const NAME_CAKEBANK = "Cake";
export const NAME_SEABANK = "SeaBank";
export const NAME_LPBANK = "LPBank";
export const NAME_BVBANK = "BVBank";
export const NAME_VIETTELMONEY = "ViettelMoney";
export const NAME_INVOICE = "Invoice";

export const NAME_OCB_BANK = "OCB";

export const ADMIN_ROLE = "Admin";

export const ROUTE_KEY = {
  JUN88: "Jun88",
  "789BET": "789BET",
  HI88: "Hi88",
  F8BET: "F8BET",
  SHBET: "SHBET",
  NEW88: "NEW88",
  MB66: "MB66",
  OK9: "OK9",
  "78WIN": "78WIN",
};
export const GAME_KEY = {
  JUN88: "JUN88",
  "789BET": "789BET",
  HI88: "HI88",
  F8BET: "F8BET",
  SHBET: "SHBET",
  NEW88: "NEW88",
  VIETCOMBANK: "VCB",
};
export const GAME_KEY_APP = {
  "VIETCOMBANKAPP APP": "VIETCOMBANKAPP",
  // BIDV: "BIDV",
  Techcombank: "TECHCOMBANK",
  ACB: "ACB",
  // MBBANK: "MBBANK",
  // VPBANK: "VPBANK",
};

export const GAME_KEY_LIST = Object.values(GAME_KEY);
export const GAME_KEY_LIST_APP = Object.values(GAME_KEY_APP);
